import * as React from "react"
import { MapViewer, MapViewerProvider } from "@allied/react-web/MapViewer"
import { LocationItem } from "./LocationItem"
import { LocationDetail } from "./LocationDetail"

type DepotLocationProps = {
  children?: {
    items?: Item[]
  }
}

type Item = {
  name: string
  street_name: string
  contact_name?: string
  contacts: ContactItem[]
}

type ContactItem = {
  type: string
  label: string
  value: string
}

export function DepotLocation(props: DepotLocationProps): React.JSX.Element {
  const locationItems = props.children && props.children.items ? props.children.items : []
  const [selected, setSelected] = React.useState<boolean>(false)
  const [selectedLocation, setSelectedLocation] = React.useState<Item>({
    name: "",
    street_name: "",
    contact_name: "",
    contacts: [],
  })

  React.useEffect(() => {
    const items = props.children && props.children.items ? props.children.items : []
    if (items.length === 0) {
      return
    }

    setSelectedLocation(items[0])
  }, [props.children])

  const locationSelected = (position: number) => {
    const location = locationItems[position]
    if (!location) {
      return
    }

    setSelectedLocation({
      ...location,
    })
    setSelected(true)
  }

  const handleBackClicked = () => {
    setSelected(false)
  }

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 lg:!col-span-7 xl:!col-span-8 lg:order-last">
        <div className="h-[300px] md:h-[400px] lg:h-[500px]">
          <MapViewer
            appendClassNames="rounded-t-lg md:rounded-t-none md:!rounded-tr-lg md:!rounded-br-lg"
            provider={MapViewerProvider.GOOGLE_MAP}
            title={selectedLocation.name}
            query={selectedLocation.street_name} />
        </div>
      </div>
      <div className="col-span-12 lg:!col-span-5 xl:!col-span-4 bg-acem-white rounded-b-lg md:rounded-b-none md:!rounded-tl-lg md:!rounded-bl-lg">
        <div className="max-h-[400px] md:max-h-[400px] lg:max-h-[500px] overflow-y-scroll">
          {
            !selected ?
              locationItems.map((location, i: number) => {
                return (
                  <LocationItem
                    key={`location-item-${i}`}
                    onClick={() => locationSelected(i)}>
                    {{
                      ...location
                    }}
                  </LocationItem>
                )
              })
              :
              <LocationDetail onBack={handleBackClicked}>
                {{
                  ...selectedLocation
                }}
              </LocationDetail>
          }
        </div>
      </div>
    </div>
  )
}
