import * as React from "react"
import { Link } from "@allied/react-web/Router"

type ContactViewerProps = {
  children: {
    items: ContactItem[]
  }
}

type ContactItem = {
  name: string
  designation: string
  emails?: string[]
  telephones?: string[]
  mobiles?: string[]
}

export function ContactViewer(props: ContactViewerProps): React.JSX.Element {
  const items = props && props.children && props.children.items ?
    props.children.items : []

  return (
    <>
      <div className="hidden md:flex">
        <table className="w-full h-full">
          <thead>
            <tr className="text-left uppercase border-y border-gray-300">
              <th className="py-4">
                Name
              </th>
              <th>
                Designation
              </th>
              <th>
                Email
              </th>
              <th>
                Telephone
              </th>
              <th>
                Mobile
              </th>
            </tr>
          </thead>
          <tbody>
            {
              items.map((contact, i: number) => {
                return (
                  <tr key={`contact-item-desktop-${i}`}>
                    <td className="py-4">
                      {contact.name}
                    </td>
                    <td>
                      {contact.designation}
                    </td>
                    <td>
                      {
                        contact.emails && contact.emails.length > 0 ?
                          <div className="flex flex-col justify-center gap-1">
                            {
                              contact.emails.map((email: string, j: number) => {
                                return (
                                  <Link key={`email-item-${i}-${j}`} to={`mailto:${email}`}
                                    className="inline-block underline text-acem-red"
                                    hideCrawl>
                                    {email}
                                  </Link>
                                )
                              })
                            }
                          </div>
                          :
                          <>-</>
                      }
                    </td>
                    <td>
                      {
                        contact.telephones && contact.telephones.length > 0 ?
                          <div className="flex flex-col justify-center gap-1">
                            {
                              contact.telephones.map((telephone: string, j: number) => {
                                return (
                                  <Link key={`telephone-item-${i}-${j}`}
                                    to={`tel:${telephone.replace(new RegExp(" ", "g"), "")}`}
                                    className="inline-block no-underline text-acem-red"
                                    hideCrawl>
                                    {telephone}
                                  </Link>
                                )
                              })
                            }
                          </div>
                          :
                          <>-</>
                      }
                    </td>
                    <td>
                      {
                        contact.mobiles && contact.mobiles.length > 0 ?
                          <div className="flex flex-col justify-center gap-1">
                            {
                              contact.mobiles.map((mobile: string, j: number) => {
                                return (
                                  <Link key={`mobile-item-${i}-${j}`}
                                    to={`tel:${mobile.replace(new RegExp(" ", "g"), "")}`}
                                    className="inline-block no-underline text-acem-red"
                                    hideCrawl>
                                    {mobile}
                                  </Link>
                                )
                              })
                            }
                          </div>
                          :
                          <>-</>
                      }
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
      <div className="md:hidden">
        <div className="flex flex-col items-center gap-2 mb-14">
          {
            items.map((contact, i: number) => {
              return (
                <div key={`contact-item-mobile-${i}`}
                  className="flex flex-col w-full text-center gap-3 py-4 border-b border-gray-300">
                  <h2 className="font-bold">
                    {contact.designation}
                  </h2>
                  <h3>
                    {contact.name}
                  </h3>
                  {
                    contact.emails && contact.emails.length > 0 ?
                      <div className="block">
                        <span>Email: {" "}</span>
                        {
                          contact.emails.map((email: string, j: number) => {
                            return (
                              <Link key={`email-item-${i}-${j}`} to={`mailto:${email}`}
                                className="mx-1 underline text-center text-acem-red"
                                hideCrawl>
                                {email}
                              </Link>
                            )
                          })
                        }
                      </div>
                      : null
                  }
                  {
                    contact.telephones && contact.telephones.length > 0 ?
                      <div className="block">
                        <span>Tel: {" "}</span>
                        {
                          contact.telephones.map((telephone: string, j: number) => {
                            return (
                              <Link key={`telephone-item-${i}-${j}`} to={`tel:${telephone}`}
                                className="mx-1 underline text-center text-acem-red"
                                hideCrawl>
                                {telephone}
                              </Link>
                            )
                          })
                        }
                      </div>
                      : null
                  }
                  {
                    contact.mobiles && contact.mobiles.length > 0 ?
                      <div className="block">
                        <span>Mobile: {" "}</span>
                        {
                          contact.mobiles.map((mobile: string, j: number) => {
                            return (
                              <Link key={`mobile-item-${i}-${j}`} to={`tel:${mobile}`}
                                className="mx-1 underline text-center text-acem-red"
                                hideCrawl>
                                {mobile}
                              </Link>
                            )
                          })
                        }
                      </div>
                      : null
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  )
}
