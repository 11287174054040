import * as React from "react"
import forwardIcon from "./../../assets/icon/forward.svg"

type LocationItemProps = {
  children?: {
    name?: string
    street_name?: string
  }
  onClick: () => void
}

export function LocationItem(props: LocationItemProps): React.JSX.Element {
  const handleClick = () => {
    props.onClick && props.onClick()
  }

  return (
    <div onClick={handleClick} className="p-6 cursor-pointer hover:bg-acem-black text-acem-black hover:!text-acem-white border-b border-gray-400">
      <div className="flex flex-row items-center justify-between gap-y-4 gap-x-12">
        <div className="flex flex-col gap-6">
          <h2 className="font-bold">
            {props.children && props.children.name}
          </h2>
          <p>
            {props.children && props.children.street_name}
          </p>
        </div>
        <img src={forwardIcon} alt="forward" />
      </div>
    </div>
  )
}
