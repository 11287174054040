import * as React from "react"
import { Outlet } from "react-router-dom"
import { Header } from "@allied/react-web/Header"
import { Footer } from "@allied/react-web/Footer"
import alliedLogo from "./../assets/logo/allied-logo.webp"
import iso9001Logo from "./../assets/logo/iso-9001-logo.jpg"
import iso14001Logo from "./../assets/logo/iso-14001-logo.jpg"
import iso45001Logo from "./../assets/logo/iso-45001-logo.jpg"
import bizsafeStarLogo from "./../assets/logo/bizsafe-star-logo.jpg"
import facebookIcon from "./../assets/icon/facebook.svg"
import linkedinIcon from "./../assets/icon/linkedin.svg"

export default function Guest(): React.JSX.Element {
  return (
    <>
      <Header.AlliedContainer
        logoImage={alliedLogo}
        logoUrl="/"
        infoItems={[
          {
            content: <>Allied Food</>,
            to: "https://alliedfood.sg/",
            target: "_blank"
          },
        ]}
        linkItems={[
          {
            content: <>About Us</>,
            to: "/#about_us"
          },
          {
            content: <>Services</>,
            to: "/#services"
          },
          {
            content: <>Regional</>,
            to: "/#regional"
          },
          {
            content: <>Depot Locations</>,
            to: "/#depot_locations"
          },
          {
            content: <>Awards</>,
            to: "/#awards"
          },
          {
            content: <>Careers</>,
            to: "/careers"
          },
          {
            content: <>Contact Us</>,
            to: "/contact-us"
          },
          {
            content: <>Allied Food</>,
            to: "https://alliedfood.sg/",
            target: "_blank"
          },
        ]}
        menuItems={[
          {
            content: <>HR Portal</>,
            to: "https://whyzehr.whyze.com.sg/",
            target: "_blank"
          },
          {
            content: <>Trucker Subcon Login</>,
            to: "/truckersubcon-login"
          },
          {
            content: <>Depot Haulier Login</>,
            to: "/haulier-login"
          },
          {
            content: <>Login</>,
            to: "/internal-login"
          },
        ]}
        navItems={[
          {
            content: <>About Us</>,
            to: "/#about_us"
          },
          {
            content: <>Services</>,
            to: "/#services"
          },
          {
            content: <>Regional</>,
            to: "/#regional"
          },
          {
            content: <>Depot Locations</>,
            to: "/#depot_locations"
          },
          {
            content: <>Awards</>,
            to: "/#awards"
          },
          {
            content: <>Careers</>,
            to: "/careers"
          },
          {
            content: <>Contact Us</>,
            to: "/contact-us"
          },
        ]}
        sticky />
      <Outlet />
      <Footer.AlliedContainer
        logoImage={alliedLogo}
        logoUrl="/"
        description="One of Singapore’s Top Container Depot"
        mediaItems={[
          {
            to: "https://www.facebook.com/AlliedContainerGroup/",
            content: (
              <img className="inline-block w-8 h-8"
                src={facebookIcon}
                alt="facebook" />
            ),
            target: "_blank"
          },
          {
            to: "https://www.linkedin.com/company/allied-container-group/",
            content: (
              <img className="inline-block w-8 h-8"
                src={linkedinIcon}
                alt="linkedin" />
            ),
            target: "_blank"
          }
        ]}
        menuItems={[
          {
            heading: "Our Companies",
            items: [
              {
                content: "Winspec Group",
                to: "https://www.winspecgroup.com/",
                target: "_blank",
              },
              {
                content: "Allied Food",
                to: "https://alliedfood.sg/",
                target: "_blank",
              }
            ]
          },
          {
            heading: "Navigation",
            items: [
              {
                content: "About Us",
                to: "/#about_us",
              },
              {
                content: "Services",
                to: "/#services",
              },
              {
                content: "Regional",
                to: "/#regional",
              },
              {
                content: "Depot Locations",
                to: "/#depot_locations",
              },
              {
                content: "Awards",
                to: "/#awards",
              },
              {
                content: "Careers",
                to: "/careers",
              },
              {
                content: "Contact Us",
                to: "/contact-us",
              },
            ]
          },
          {
            heading: "Resources",
            items: [
              {
                content: "Depot Contact",
                to: "/file/allied_depots_contacts%20(Updated%2023.05.2024).xlsx",
                target: "_blank",
                hideCrawl: true,
              },
              {
                content: "HR Portal",
                to: "https://alliedgroup.smepayroll.com/Login_soft.aspx?pid=WMSAMC",
                target: "_blank"
              },
              {
                content: "Trucker Subcon Login",
                to: "/truckersubcon-login"
              },
              {
                content: "Depot Haulier Login",
                to: "/haulier-login",
              },
              {
                content: "Login",
                to: "/internal-login",
              },
            ]
          }
        ]}
        certificateItems={[
          {
            content: (
              <img className="w-20 h-20"
                src={iso9001Logo}
                alt="ISO 9001 Quality Management Certification" />
            )
          },
          {
            content: (
              <img className="w-20 h-20"
                src={iso14001Logo}
                alt="ISO 14001 Environmental Management Certification" />
            )
          },
          {
            content: (
              <img className="w-20 h-20"
                src={iso45001Logo}
                alt="ISO 45001 Health & Safety Management Certification" />
            )
          },
          {
            content: (
              <img className="w-30 h-20"
                src={bizsafeStarLogo}
                alt="bizSAFE STAR Workplace Safety and Health Management System Certification" />
            )
          }
        ]}
        certificateDescription="An ISO 9001, ISO 14001, ISO 45001 and bizSAFE STAR Certified Company"
        />
    </>
  )
}
