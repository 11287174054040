import * as React from "react"
import { ButtonLink, ButtonVariant } from "@allied/react-web/Button"
import { Meta } from "@allied/react-web/Meta"

export default function NotFound(): React.JSX.Element {
  return (
    <>
      <Meta>
        {{
          title: "404 Page not found"
        }}
      </Meta>
      {/* 
        @note: do not remove, make sure to match the html element on the homepage
        @issue: react-snap do not handle 404 page correctly
        @ref: https://gitlab.allied.com.sg/web/acem-singapore/-/merge_requests/30
      */}
      <div>
        <div>
          <div className="py-10 md:!py-16 px-3 md:!px-24 3xl:!px-64">
            <div className="flex flex-col gap-2 justify-center items-start p-6 md:p-10">
              <h1 className="font-bold text-2xl">
                Not Found
              </h1>
              <p>
                Sorry we could not find this page
              </p>
              <div className="py-4">
                <ButtonLink to="/" variant={ButtonVariant.PRIMARY}>
                  Back to Home
                </ButtonLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
