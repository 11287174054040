import * as React from "react"
import { Accordion, AccordionSize } from "@allied/react-web/Accordion"
import { ButtonLink, ButtonSize } from "@allied/react-web/Button"
import { Select, SelectSize } from "@allied/react-web/Select"
import { Meta } from "@allied/react-web/Meta"
import { useAutoPosition } from "@allied/react-web/hooks"
import { Job as JobService } from "../services/gsheet/Job"
import careerIcon from "./../assets/icon/career.svg"
import employmentIcon from "./../assets/icon/employment.svg"
import departmentIcon from "./../assets/icon/department.svg"

const filterEmployments = [
  {
    value: "",
    label: "All Employment Types"
  },
  {
    value: "Full Time",
    label: "Full Time"
  },
  {
    value: "Part Time",
    label: "Part Time"
  },
  {
    value: "Contract",
    label: "Contract"
  }
]
const filterDepartments = [
  {
    value: "",
    label: "All Departments"
  },
  {
    value: "Operations",
    label: "Operations"
  },
  {
    value: "HR",
    label: "HR"
  },
  {
    value: "Accounts",
    label: "Accounts"
  },
  {
    value: "IT",
    label: "IT"
  },
]

type JobItem = {
  title: string
  description: string
  responsibility: string
  requirement: string
  company_name: string
  department_name: string
  employment_type: string
}

const jobService = new JobService()

export default function Career(): React.JSX.Element {
  const [jobs, setJobs] = React.useState<JobItem[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [filter, setFilter] = React.useState({
    employment: "",
    department: ""
  })

  useAutoPosition()

  React.useEffect(() => {
    async function searchJob() {
      setLoading(true)
      const searchJob = await jobService.SearchJob({
        department: filter.department,
        employment: filter.employment,
      })
      setLoading(false)
      if (searchJob.error) {
        return
      }

      setJobs(searchJob.data.items)
    }

    searchJob()
  }, [filter])

  return (
    <>
      <Meta>
        {{
          title: "Careers",
          description: "Allied Container Recruitment. Join our team and make a difference!"
        }}
      </Meta>
      <div className="py-10 md:!py-16 px-3 md:!px-24 3xl:!px-64">
        <div className="flex flex-col flex-wrap justify-center">
          <div className="flex md:flex-row-reverse flex-wrap md:flex-nowrap justify-center items-center">
            <div className="w-full md:!w-1/2">
              <img src={careerIcon} alt="career" className="w-full h-full" />
            </div>
            <div className="w-full md:!w-1/2">
              <h1 className="text-center md:!text-left text-acem-red text-3xl md:!text-4xl font-bold uppercase py-2">
                Hey There, We're Hiring
              </h1>
              <h2 className="text-center md:!text-left text-acem-black text-lg md:!text-xl font-thin">
                Join our team and make a difference!
              </h2>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row md:flex-nowrap justify-center items-start gap-4">
            <div className="w-full lg:!w-1/3">
              <div className="md:bg-acem-white">
                <div className="hidden md:!block p-4">
                  <span className="text-xl text-acem-black font-bold">
                    Filter
                  </span>
                </div>
                <hr className="hidden md:!block border-t-1 border-gray-400" />
                <div className="flex flex-col justify-center items-center gap-4 p-4">
                  <Select size={SelectSize.LARGE}
                    id="filter-employment-type"
                    name="filter-employment-type"
                    onChange={(e) => {
                      setFilter((prevState) => {
                        return {
                          ...prevState,
                          employment: e.target.value
                        }
                      })
                    }}>
                    {
                      filterEmployments.map((filter, i: number) => {
                        return (
                          <Select.Item key={`employment-type-item-${i}`} value={filter.value}>
                            {filter.label}
                          </Select.Item>
                        )
                      })
                    }
                  </Select>
                  <Select size={SelectSize.LARGE}
                    id="filter-department"
                    name="filter-department"
                    onChange={(e) => {
                      setFilter((prevState) => {
                        return {
                          ...prevState,
                          department: e.target.value
                        }
                      })
                    }}>
                    {
                      filterDepartments.map((filter, i: number) => {
                        return (
                          <Select.Item key={`department-item-${i}`} value={filter.value}>
                            {filter.label}
                          </Select.Item>
                        )
                      })
                    }
                  </Select>
                </div>
              </div>
            </div>
            <div className="w-full lg:!w-2/3">
              <span className="font-medium">
                {jobs.length} Jobs found
              </span>

              <div className="py-2">
                {
                  loading &&
                  <div className="bg-acem-white p-2">
                    <span className="font-semibold">
                      Loading...
                    </span>
                  </div>
                }

                {
                  !loading && jobs.length === 0 &&
                  <div className="bg-acem-white p-2">
                    <span className="font-semibold">
                      No matched job at the moment
                    </span>
                  </div>
                }

                {
                  !loading && jobs.length > 0 &&
                  <Accordion size={AccordionSize.MEDIUM}>
                    {{
                      items: jobs.map((job) => {
                        return {
                          header: (
                            <h2 className="font-semibold">
                              {job.title}
                            </h2>
                          ),
                          content: (
                            <>
                              <div className="flex justify-start items-center gap-2 w-full my-2">
                                <img className="w-4 h-4" src={employmentIcon} alt="employement" />
                                <span>
                                  {job.employment_type}
                                </span>
                                <img className="w-4 h-4" src={departmentIcon} alt="department" />
                                <span>
                                  {job.department_name}
                                </span>
                              </div>
                              <div className="my-2">
                                <div dangerouslySetInnerHTML={{ __html: job.description }}></div>
                              </div>
                              <div className="my-2">
                                <span className="underline">Key Responsibilities:</span>
                                <div dangerouslySetInnerHTML={{ __html: job.responsibility }}></div>
                              </div>
                              <div className="my-2">
                                <span className="underline">Requirements:</span>
                                <div dangerouslySetInnerHTML={{ __html: job.requirement }}></div>
                              </div>
                              <ButtonLink size={ButtonSize.MEDIUM} to="mailto:careers@allied.com.sg">
                                Apply
                              </ButtonLink>
                            </>
                          )
                        }
                      })
                    }}
                  </Accordion>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
