import * as React from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"
import ReactGA from "react-ga4"
import { MetaProvider } from "@allied/react-web/Meta"
import { ThemeProvider } from "@allied/react-web/Theme"
import { routes } from "../../routes"
import "./App.css"

ReactGA.send({ hitType: "pageview", page: window.location.pathname })

const meta = {
  titleTemplate: "%s - Allied Container Group",
  titleFallback: "Allied Container Group - Yard Operator, Haulier Operator and 3PL Provider",
  descriptionFallback: "Official website for Allied Containers Services and Allied Container (Engineers and Manufacturers) - Allied Group, Singapore | Largest container yard operator and container haulier | Allied depot",
  keywordsFallback: "allied, container, yard, operator, haulier, engineer, manufacturer, depot, singapore",
  typeFallback: "website",
  urlFallback: window.location.href,
  imageFallback: `${process.env.REACT_APP_PUBLIC_URL}/image/og-img.webp`,
  markupSchemaFallback: {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Allied Container Group",
    "image": "https://allied.com.sg/logo/allied-logo.webp",
    "@id": "https://allied.com.sg/",
    "url": "https://allied.com.sg/",
    "telephone": "+6565586079",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "6 Tuas Ave 6",
      "addressLocality": "Singapore",
      "postalCode": "639311",
      "addressCountry": "SG"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 1.3258101,
      "longitude": 103.6501101
    },
    "sameAs": [
      "https://www.facebook.com/AlliedContainerGroup/",
      "https://www.linkedin.com/company/allied-container-group/"
    ]
  },
  twitterSchemaFallback: {
    "card": "summary_large_image",
  }
}
export default function App(): React.JSX.Element {
  return (
    <>
      <ThemeProvider theme="acem">
        <MetaProvider meta={meta}>
          <Router>
            <Routes>
              {
                routes.map((route, i) => {
                  return <Route {...route} key={`route-item-${i}`} />
                })
              }
            </Routes>
          </Router>
        </MetaProvider>
      </ThemeProvider>
    </>
  )
}
