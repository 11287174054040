import * as React from "react"
import { Link } from "react-router-dom"
import backIcon from "./../../assets/icon/back.svg"

type LocationDetailProps = {
  children?: {
    name: string
    street_name: string
    contact_name?: string
    contacts: ContactItem[]
  }
  onBack?: () => void
}

type ContactItem = {
  type: string
  label: string
  value: string
}

export function LocationDetail(props: LocationDetailProps): React.JSX.Element {
  const contacts = props && props.children && props.children.contacts ? props.children.contacts : []
  const handleBack = () => {
    props.onBack && props.onBack()
  }

  function generateContactUrl(contact: ContactItem): string {
    let url = ""
    switch (contact.type) {
      case "email":
        url = `mailto:${contact.value.replace(new RegExp(" ", "g"), "")}`
        break
      case "tel":
        url = `tel:${contact.value.replace(new RegExp(" ", "g"), "")}`
        break
      default:
    }
    return url
  }

  return (
    <div className="min-h-[500px] text-acem-white bg-acem-black duration-150">
      <div className="flex justify-start items-center py-4 cursor-pointer" onClick={handleBack}>
        <img className="pl-4" src={backIcon} alt="back" />
        <span className="px-4">
          Back to listing
        </span>
      </div>

      <div className="flex flex-col gap-4 p-4 border-y border-acem-white">
        <h2 className="font-bold">
          {props.children && props.children.name}
        </h2>
        <p>
          {props.children && props.children.street_name}
        </p>
      </div>

      <div className="flex flex-col gap-4 p-4">
        <h2 className="font-bold">
          {props.children && props.children.contact_name}
        </h2>
        {
          contacts.length > 0 ?
            <ul>
              {
                contacts.map((contact, i: number) => {
                  return (
                    <li key={`contact-item-${i}`}>
                      {contact.label}: {" "}
                      <Link to={generateContactUrl(contact)}>
                        {contact.value}
                      </Link>
                    </li>
                  )
                })
              }
            </ul>
            : null
        }
      </div>
    </div>
  )
}
